import Image from "next/image";
import Link from "next/link";
import { useState, useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";
import Button from "../ui/button";

// Define the types for project data
interface Project {
  id: string;
  title: string;
  description: string;
  image: string;
  slug: string;
  link: string;
}

// Helper function to randomize the layout for each card
function randomizeLayout(): boolean {
  return Math.random() > 0.5;
}

// Helper function to check if the media is a video
function isVideo(src: string): boolean {
  return src.endsWith(".mp4"); // You can add more video formats here if needed
}

interface ProjectCardProps {
  projects: Project[];
}

export default function ProjectCard({ projects }: ProjectCardProps) {
  const [layouts, setLayouts] = useState<boolean[]>([]);

  // Generate the layout shuffle when the component mounts
  useEffect(() => {
    const initialLayouts = projects.map(() => randomizeLayout());
    setLayouts(initialLayouts);
  }, [projects]);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-50px" });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, ease: "easeOut" }}
      className="flex flex-col md:grid justify-center rounded-xl bg-white mx-auto mb-10 p-2 md:px-0 lg:px-10 dark:bg-transparent dark:text-gray-100"
    >
      <div className="fade-in-custom min-w-full mx-auto">
        {/* Projects grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          {projects.map((project, index) => {
            const isTitleAbove = layouts[index]; // Get the layout for this specific project card

            return (
              <div key={project.id} className="mb-10 transition-shadow">
                {/* Conditionally render the title/description and image */}
                {isTitleAbove ? (
                  <>
                    {/* Title and Description Above the Image/Video */}
                    <div className="mb-4">
                      <h1 className="text-2xl tracking-wide font-semibold text-gray-800 mb-6 dark:text-gray-200">
                        {project.title}
                      </h1>
                      <p className="text-xl text-gray-600 mb-10 dark:text-gray-200">
                        {project.description}
                      </p>
                    </div>
                    <Link 
                      href={`/works/${project.slug}`}
                      aria-label="{project.title}"
                    >
                      <div className="relative rounded-2xl w-full h-[26rem] mb-10 border bottom-8 border-gray-300">
                        {/* Conditional rendering for image or video */}
                        {isVideo(project.image) ? (
                          <VideoOnHover src={project.image} />
                        ) : (
                          <Image
                            src={project.image}
                            alt={project.title}
                            width={438}
                            height={416}
                            className="absolute object-center top-0 left-0 transition bg-gray-200 rounded-xl object-cover" // Use object-cover with className
                          />
                        )}
                      </div>
                    </Link>
                  </>
                ) : (
                  <>
                    {/* Image/Video Above the Title and Description */}
                    <div className="relative w-full rounded-2xl h-[26rem] mb-10 border bottom-8 border-gray-300">
                      <Link href={`/works/${project.slug}`} aria-label="{project.title}">
                        {/* Conditional rendering for image or video */}
                        {isVideo(project.image) ? (
                          <VideoOnHover src={project.image} />
                        ) : (
                          <Image
                            src={project.image}
                            alt={project.title}
                            width={438}
                            height={416}
                            className="absolute object-center top-0 left-0 transition bg-gray-200 rounded-2xl object-cover" // Use object-cover with className
                          />
                        )}
                      </Link>
                    </div>
                    <div className="mb-4">
                      <h1 className="text-2xl tracking-wide font-semibold text-gray-800 mb-6 dark:text-gray-200">
                        {project.title}
                      </h1>
                      <p className="text-xl text-gray-600 mb-10 dark:text-gray-200">
                        {project.description}
                      </p>
                    </div>
                  </>
                )}

                {/* View Project Button */}
                <div className="mt-6">
                  <Link
                    href={
                      project.link && project.link !== ""
                        ? project.link
                        : `/works/${project.slug}`
                    } // Check if project.link is non-empty, otherwise use project.slug
                    target="_blank"
                    aria-label="project slug"
                    // className="py-3 px-6 mb-2 text-base font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-black dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-[#181D2A] transition-colors"
                  >
                    <Button variant="flat">View project</Button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
}

// VideoOnHover Component
const VideoOnHover = ({ src }: { src: string }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Reset video playback to the start
    }
  };

  return (
    <video
      ref={videoRef}
      src={src}
      className="absolute top-0 left-0 w-full h-full object-cover transition z-[1] bg-gray-100 rounded-xl"
      muted
      loop
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

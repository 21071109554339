import Link from "next/link";
import { NextSeo } from "next-seo";
import Layout from "@/components/layouts/layout";
import Hero from "@/components/Card/hero";
import Container from "@/components/ui/container";
import ProjectCard from "@/components/projects/ProjectCard";
import { Projects } from "@/data/projects";

export default function Home() {
  // Filter the first two projects
  const firstTwoProjects = Projects.slice(0, 2);

  return (
    <>
      {/* <NextSeo
        title="Home"
        description="Welcome to my website. Explore my projects, tech insights, and personal journey."
        openGraph={{
          url: "https://princemiller.com",
          title: "Prince Miller – Home",
          description:
            "Welcome to my website. Explore my projects, tech insights, and personal journey.",
          images: [
            {
              url: "https://princemiller.com/static/images/home-banner.png",
            },
          ],
        }}
      /> */}

      <Container>
        <Hero />

        <section className="flex flex-col justify-center items-start max-w-7xl !mx-0 px-0 md:px-0 mb-16">
          <h1 className="fade-in-custom font-semibold text-3xl md:text-3xl tracking-tight mb-14 mt-16 md:pl-10 text-gray-800 dark:text-white">
            My Works
          </h1>

          <ProjectCard projects={firstTwoProjects} />

          <div className="flex justify-center text-center">
            <Link
              href="/works"
              type="button"
              className="flex items-center text-sm my-4 mx-auto px-4 py-2 rounded-md font-medium text-gray-900 dark:text-gray-100"
            >
              {/* all work
              <svg className="h-4 w-4 ml-1 hover:animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"/>
              </svg> */}
            </Link>
          </div>
        </section>
      </Container>
    </>
  );
}

Home.Layout = Layout;

// /data/projects.ts
export const Projects = [
  {
    id: 1,
    slug: "fosom",
    title: "Fosom",
    description: "A social commerce platform for wardrobe shopping, browsing, and inspiration.",
    type: "E-Commerce",
    // image: "/images/ipad.png",
    image: "/videos/fosom.mp4",
    link: "https://www.fosom.com/en",
    role: "Lead - Infrastructure",
    duration: "6 Months",
    // teammates: ["",],
    tools: [ ],
  },
  {
    id: 2,
    slug: "gews",
    title: "Green Earth Waldorf School",
    type: "Side",
    image: "/images/gews.edu.gh.png",
    link: "https://www.gews.edu.gh",
    role: "Web Developer",
    duration: "1 Months",
    // teammates: [""],
    tools: [""],
    description:
      "Redesign project focused on improving user engagement and usability.",
  },
  {
    id: 3,
    slug: "blog-fosom",
    title: "Fosom Blog",
    type: "Side",
    image: "/images/blog-fosom.png",
    link: "https://blog.fosom.com/",
    role: "Designer & Develop",
    duration: "3 Months",
    // teammates: ["Padi John"],
    tools: [""],
    description:
      "Ecommerce Marketing Tips, Guides and More - Fosom",
  },
  {
    id: 4,
    slug: "buildtext-sms",
    title: "BuildText: Construction SMS Manager",
    type: "Side",
    image: "/images/buildersms.png",
    link: "",
    role: "Lead Developer",
    duration: "4 Months",
    // teammates: ["Fatou Bintu"],
    tools: [""],
    description: "BuildText is a user-friendly SMS communication app designed for construction projects",
  }
  // Add more projects as needed
];

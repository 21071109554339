import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion, useInView } from "framer-motion";
import Link from "next/link";
import { FiLinkedin, FiMail } from "react-icons/fi";
import FadeInSection from "@/components/ui/FadeInSection";

export default function Hero() {
  const [open, setOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const variants = {
    hidden: { opacity: 0, y: "1vh" },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
    exit: { opacity: 0, y: "1vh" },
  };

  const itemA = {
    hidden: { opacity: 0, scale: 0.5, y: "1vh" },
    show: { opacity: 1, scale: 1, y: 0 },
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-50px" });
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, ease: "easeOut" }}
      // className="flex flex-col md:grid md:grid-cols-3 justify-center rounded-xl bg-white mx-auto mb-10 p-2 md:px-0 lg:px-10 dark:bg-transparent dark:text-gray-100"
    >
      <div className=" flex-col w-full lg:max-w-7xl  mt-16 md:my-24 lg:my-36 lg:px-10">
        {/* Introduction */}
        <div className="md:mt-[36px] mb-6 text-gray-600 dark:text-gray-300">
          <span>Hi, I'm Miller! </span>
          <span role="img" aria-label="coffee">
            ®
          </span>
        </div>

        {/* Main Description */}
        <FadeInSection>
          <h1 className="w-[100%-20%] bg-gradient-to-r from-gray-500 via-gray-800 to-gray-500 bg-clip-text text-transparent text-4xl md:text-5xl font-normal leading-[142%;] md:leading-[130%;] antialiased">
            Accra based{" "}
            <span className="text-black dark:text-gray-400">
              Reliability Engineer,
            </span>{" "}
            a proactive problem solver{" "}
            <span className="text font-light dark:text-gray-200">
              keeping systems running at{" "}
              <span className="text-gray-700">peak efficiency</span>.
            </span>
          </h1>
        </FadeInSection>

        {/* Buttons */}
        <div className="flex mt-10 mb-[36px] items-center space-x-4">
          {/* Resume Button */}
          <a
            href="https://drive.google.com/file/d/1XSb8WJXD8_w1jXmrG8t4t-VeA-W8z3YV/view"
            target="__blank"
            className="px-6 py-3 bg-gray-700 text-white rounded-lg shadow hover:bg-gray-800"
          >
            Resume
          </a>

          {/* Social Media Icons */}
          <Link
            href="https://linkedin.com/in/yhmiller"
            target="__blank"
            aria-label="social_icon"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full hover:bg-gray-300"
          >
            <FiLinkedin className="text-gray-700 w-6 h-6" />
          </Link>
          <Link
            href="mailto:hi@princemiller.com"
            aria-label="social_icon"
            className="flex items-center justify-center w-12 h-12 bg-gray-200 rounded-full hover:bg-gray-300"
          >
            <FiMail className="text-gray-700 w-6 h-6" />
          </Link>

          {/* something cool right? */}
          <span
            className="max-w-44 font-bold cursor-pointer text-gray-800 dark:text-gray-200"
            style={{ cursor: "🤩" }}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            {" "}
            😉
            <AnimatePresence>
              {open && (
                <motion.div
                  key="modal"
                  variants={variants}
                  initial="hidden"
                  animate="show"
                  exit="exit"
                  className="z-20 p-3 rounded-xl bg-white dark:bg-gray-800 shadow-2xl absolute inset-x-56 md:inset-x-[-10] w-32 md:w-56 min-h-96 text-xs md:text-sm"
                >
                  <div className="bg-grid-slate-50 dark:bg-grid-gray-700/30 absolute inset-0 [mask-image:linear-gradient(to_bottom,transparent,white,transparent)]"></div>
                  <motion.p variants={itemA} className="font-normal py-3">
                    Co-founder{" "}
                    <Link href="https://www.blustrap.com">@BluStrap</Link> and{" "}
                    <Link href="https://www.fosom.com">@Fosom</Link>
                  </motion.p>

                  <motion.p variants={itemA} className="font-normal py-3">
                    <Link href="/playground">@Playground</Link>
                  </motion.p>
                </motion.div>
              )}
            </AnimatePresence>
          </span>
        </div>
      </div>
    </motion.div>
  );
}

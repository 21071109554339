// /components/ui/container.tsx
import cn from "classnames";
import { ReactNode, ElementType } from "react";

type Props = {
  className?: string;
  children?: ReactNode;
  el?: ElementType;
  clean?: boolean;
};

const Container = ({ children, className, el: Component = "div", clean }: Props) => {
  const rootClassName = cn(className, {
    "mx-auto max-w-[1920px] px-4 md:px-8 2xl:px-16": !clean,
  });

  return <Component className={rootClassName}>{children}</Component>;
};

export default Container;
